<template>
  <div :class="$style.root">
    <div :class="$style.content">
      <BaseStrapiImageComponent
        :image="image"
        :data-orientation="orientation"
        :key="image?.url"
      />

      <div
        :class="$style.caption"
        v-if="caption && includeCaption"
        :key="caption"
      >
        {{ caption }}
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  image: Object,
  includeCaption: {
    type: Boolean,
    default: true
  }
})

const caption = computed(() => {
  const caption = {
    i18n_caption_en: props?.image?.i18n_caption_en,
    i18n_caption_al: props?.image?.i18n_caption_al
  }
  return i18nGetLocalizedString(i18nGetLocalizedData(caption), 'i18n_caption_en')
})

const orientation = computed(() => {
  if (!props?.image?.attributes?.width && !props?.image?.width) return null
  const width = props?.image?.attributes?.width || props?.image?.width
  const height = props?.image?.attributes?.height || props?.image?.height
  if (width > height) {
    return 'landscape'
  } else if (width < height) {
    return 'portrait'
  } else {
    return 'square'
  }
})
</script>

<style module>
.root img {
  filter: invert(100%) grayscale(100%);
  transition: filter .5s .25s;
}

.root img[class*='lazyloaded'] {
  filter: invert(0%);
}

.content {
  position: relative;
}

.caption {
  font-size: .95rem;
  line-height: 1.35rem;

  position: absolute;

  bottom: 0;
  left: 0;
  right: 0;

  padding: var(--unit--spacer) var(--unit--horizontal);

  color: var(--color--secondary);
  opacity: .75;

  transition: opacity var(--transition--default);
}
</style>
